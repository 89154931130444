import { liteClient } from "algoliasearch/lite";
import { InstantSearch, useInstantSearch } from "react-instantsearch";
import AlgoliaTrialList from "../components/AlgoliaTrialList";
import AlgoliaTrialSearchBox from "../components/AlgoliaTrialSearchBox";
import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import React from "react";
import PersonalViewApi from "../api/PersonalView.api";
import UserApi from "../api/User.api";
import { useSession } from "../contexts/Session.context";
import { Grid, Box, CircularProgress } from '@mui/material'
import { SortDirection } from '../types'
import { Dayjs } from "dayjs";
import usePersistState from '../usePersistState'

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function PersonalizedTrials() {
  const [excludeYears, setExcludeYears] = usePersistState("excludeYears", false);
  const [orderBy, setOrderBy] = usePersistState("orderBy", "harvestDate")
  const [orderDirection, setOrderDirection] = usePersistState<SortDirection>("sortDirection", "desc")

  const [mexico, setMexico] = usePersistState("mexic", true);
  const [archived, setArchived] = usePersistState("archived", false);

  const [wetDateStart, setWetDateStart] = React.useState<Dayjs | null>(null);
  const [wetDateEnd, setWetDateEnd] = React.useState<Dayjs | null>(null);
  const [harvestDateStart, setHarvestDateStart] = React.useState<Dayjs | null>(
    null
  );
  const [harvestDateEnd, setHarvestDateEnd] = React.useState<Dayjs | null>(
    null
  );

  const { session } = useSession();
  const { data: user, isFetched: userDataFetched } = UserApi.useDetail(session?.userId);
  const { data: personalViews, isFetched: personalViewsFetched } = PersonalViewApi.useList(session?.userId);

  if(!userDataFetched || !personalViewsFetched){
    return (
      <Grid>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <CircularProgress size={80} thickness={7} color={"primary"} />
        </Box>
      </Grid>
    )
  }

  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={`Trial_${process.env.REACT_APP_ENV}`}
        insights
      >
        <InsightsMiddleware />
        <AlgoliaTrialSearchBox
          user={user}
          personalViews={personalViews}
          personalized={true}
          excludeYears={excludeYears}
          setExcludeYears={setExcludeYears}
          orderBy={orderBy}
          orderDirection={orderDirection}
          mexico={mexico}
          setMexico={setMexico}
          archived={archived}
          setArchived={setArchived}
          wetDateStart={wetDateStart}
          setWetDateStart={setWetDateStart}
          wetDateEnd={wetDateEnd}
          setWetDateEnd={setWetDateEnd}
          harvestDateStart={harvestDateStart}
          setHarvestDateStart={setHarvestDateStart}
          harvestDateEnd={harvestDateEnd}
          setHarvestDateEnd={setHarvestDateEnd}
        />
        <AlgoliaTrialList
          personalized={true}
          excludeYears={excludeYears}
          setExcludeYears={setExcludeYears}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          mexico={mexico}
          archived={archived}
          wetDateStart={wetDateStart}
          wetDateEnd={wetDateEnd}
          harvestDateStart={harvestDateStart}
          harvestDateEnd={harvestDateEnd}
        />
      </InstantSearch>
    </>
  );
}

function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch();

  React.useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({
      // @ts-ignore
      insightsClient: window.aa,
    });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
}
