import { useMutation, useQuery, useInfiniteQuery } from "react-query";
import {
  handleBasicGet,
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleSave,
  handleSimpleList,
  handleInfiniteList
} from "./handler";
import {  Trial, TrialInput, SortDirection } from "../types";
import { isEmpty, snakeCase } from "lodash";
import queryClient from "./queryClient";
import { Dayjs } from 'dayjs'

const methods = {
  useInfiniteList: ({
    orderBy,
    orderDirection,
    excludeYears,
    mexico,
    archived,
    personalized,
    wetDateStart,
    wetDateEnd,
    harvestDateStart,
    harvestDateEnd,
    enabled,
  }: {
    orderBy: string,
    orderDirection: SortDirection,
    excludeYears: boolean,
    mexico: boolean,
    archived: boolean,
    personalized: boolean,
    wetDateStart: Dayjs | null,
    wetDateEnd: Dayjs | null,
    harvestDateStart: Dayjs | null,
    harvestDateEnd: Dayjs | null,
    enabled: boolean
  }) => {
    return useInfiniteQuery({
      enabled,
      queryKey: [
        'infinite_trials',
        orderBy,
        orderDirection,
        excludeYears,
        mexico,
        archived,
        personalized,
        wetDateStart,
        wetDateEnd,
        harvestDateStart,
        harvestDateEnd
      ],
      queryFn:
        handleInfiniteList<Trial>({
          baseUrl: `trials`,
          params: [
            { key: "order_by", value: snakeCase(orderBy) },
            { key: "order_direction", value: orderDirection },
            { key: "exclude_years", value: excludeYears },
            { key: "mexico", value: mexico },
            { key: "archived", value: archived },
            { key: "personalized", value: personalized },
            { key: "wet_date_start", value: wetDateStart ? wetDateStart.format("YYYY-MM-DD") : null },
            { key: "wet_date_end", value: wetDateEnd ? wetDateEnd.format("YYYY-MM-DD") : null },
            { key: "est_harvest_date_start", value: harvestDateStart ? harvestDateStart.format("YYYY-MM-DD") : null },
            { key: "est_harvest_date_end", value: harvestDateEnd ? harvestDateEnd.format("YYYY-MM-DD") : null },
          ]
        }),
      keepPreviousData: false,
      getNextPageParam: (lastPage, pages) => lastPage?.meta?.nextPage,
      getPreviousPageParam: (firstPage, pages) => firstPage?.meta?.prevPage,
    })
  },
  useListByVarietyIds: (varietyIds: string[]) => {
    return useQuery<Trial[]>({
      queryKey: ["trial_by_variety_ids", varietyIds.join(",")],
      queryFn: () =>
        handleSimpleList({
          url: `trials/by_variety_ids`,
          params: [{ key: "ids", value: varietyIds.join(",") }],
        }),
      enabled: !isEmpty(varietyIds),
    });
  },
  usePrintList: () => {
    return useMutation({
      mutationFn: ({
        searchField,
        filtersHash,
        tagFilters,
        order,
        orderBy,
        personalized,
        excludeYears,
        mexico,
        archived,
        wetDateStart,
        wetDateEnd,
        harvestDateStart,
        harvestDateEnd,
      }: {
        searchField: string;
        filtersHash: any;
        tagFilters: any;
        order: string;
        orderBy: string;
        personalized: boolean;
        excludeYears: boolean,
        mexico: boolean,
        archived: boolean,
        wetDateStart: Dayjs | null,
        wetDateEnd: Dayjs | null,
        harvestDateStart: Dayjs | null,
        harvestDateEnd: Dayjs | null,
      }) =>
        handleBasicPost({
          url: "trials/list",
          input: {
            searchField,
            filtersHash,
            tagFilters,
            order,
            orderBy: snakeCase(orderBy),
            personalized,
            archived,
            excludeYears,
            wetDateStart: wetDateStart ? wetDateStart.format("YYYY-MM-DD") : null,
            wetDateEnd: wetDateEnd ? wetDateEnd.format("YYYY-MM-DD") : null,
            harvestDateStart: harvestDateStart ? harvestDateStart.format("YYYY-MM-DD") : null,
            harvestDateEnd: harvestDateEnd ? harvestDateEnd.format("YYYY-MM-DD") : null
          },
        }),
    });
  },
  useExcelList: () => {
    return useMutation({
      mutationFn: ({
        searchField,
        filtersHash,
        tagFilters,
        personalized,
        order,
        orderBy,
        excludeYears,
        mexico,
        archived,
        wetDateStart,
        wetDateEnd,
        harvestDateStart,
        harvestDateEnd,
      }: {
        searchField: string;
        filtersHash: any;
        tagFilters: any;
        personalized: boolean;
        order: string;
        orderBy: string;
        mexico: boolean,
        archived: boolean,
        excludeYears: boolean,
        wetDateStart: Dayjs | null,
        wetDateEnd: Dayjs | null,
        harvestDateStart: Dayjs | null,
        harvestDateEnd: Dayjs | null,
      }) =>
        handleBasicPost({
          url: "trials/excel_list",
          input: {
            searchField,
            filtersHash,
            tagFilters,
            personalized,
            order,
            orderBy: snakeCase(orderBy),
            archived,
            excludeYears,
            wetDateStart: wetDateStart ? wetDateStart.format("YYYY-MM-DD") : null,
            wetDateEnd: wetDateEnd ? wetDateEnd.format("YYYY-MM-DD") : null,
            harvestDateStart: harvestDateStart ? harvestDateStart.format("YYYY-MM-DD") : null,
            harvestDateEnd: harvestDateEnd ? harvestDateEnd.format("YYYY-MM-DD") : null
          },
        }),
    });
  },
  useGeneratePlotMap: ({ trialId }: { trialId?: number | string }) => {
    return useMutation({
      mutationFn: ({ supplierId }: { supplierId?: number }) =>
        handleBasicPost({
          url: `trials/${trialId}/plot_report`,
          input: {
            supplierId,
          },
        }),
    });
  },
  useGenerateEvaluationReport: ({ trialId }: { trialId?: number | string }) => {
    return useMutation({
      mutationFn: ({ supplierId }: { supplierId?: number }) =>
        handleBasicPost({
          url: `trials/${trialId}/evaluation_report`,
          input: {
            supplierId,
          },
        }),
    });
  },
  useGenerateEvaluationReportExcel: ({
    trialId,
  }: {
    trialId?: number | string;
  }) => {
    return useMutation({
      mutationFn: ({ supplierId }: { supplierId?: number }) =>
        handleBasicPost({
          url: `trials/${trialId}/evaluation_report_excel`,
          input: {
            supplierId,
          },
        }),
    });
  },
  useSendEvaluationEmail: ({
    trialId,
    supplierId,
  }: {
    trialId?: number | string;
    supplierId?: number | string;
  }) => {
    return useMutation({
      mutationFn: (peopleIds: number[]) =>
        handleBasicPost({
          url: `trials/${trialId}/send_evaluation_report`,
          input: {
            supplierId,
            people: peopleIds,
          },
        }),
    });
  },
  useSendPlotMapEmail: ({
    trialId,
    supplierId,
  }: {
    trialId?: number | string;
    supplierId?: number | string;
  }) => {
    return useMutation({
      mutationFn: (peopleIds: number[]) =>
        handleBasicPost({
          url: `trials/${trialId}/send_plot_report`,
          input: {
            supplierId,
            people: peopleIds,
          },
        }),
    });
  },
  useReportStatus: ({
    trialId,
    reportId,
  }: {
    trialId: number;
    reportId: number;
  }) => {
    return useQuery({
      queryKey: ["trialReport", trialId.toString(), reportId?.toString()],
      queryFn: () =>
        handleBasicGet({
          url: `trials/${trialId}/report_status`,
          params: [{ key: "report_id", value: reportId }],
        }),
      enabled: !!reportId,
      cacheTime: 0,
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery<Trial>({
      queryKey: ["trials", id?.toString()],
      queryFn: () => handleDetail({ baseUrl: "trials", id }),
      enabled: !!id,
    });
  },
  useCreate: () => {
    return useMutation<Trial>({
      mutationFn: () =>
        handleSave({
          baseUrl: "trials",
          input: {},
        }),
      onSuccess: async (trial: Trial) => {
        queryClient.setQueryData(
          ["trials", trial?.id?.toString()],
          (oldData: any) => {
            return trial;
          }
        );
      },
    });
  },
  useSave: (input: TrialInput) => {
    return useMutation<Trial>({
      mutationFn: () =>
        handleSave<Trial>({
          baseUrl: "trials",
          input,
        }),
      onSuccess: async (trial: Trial) => {
        queryClient.setQueryData(
          ["trials", trial?.id?.toString()],
          (oldData: any) => {
            return trial;
          }
        );
      },
    });
  },
  //  ["trial", id?.toString()],
  useDelete: (id?: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "trials", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.invalidateQueries({ queryKey: ["trials", id?.toString()] });
      },
    });
  },
};
export default methods;
