import * as Sentry from "@sentry/react";
import camelcaseKeys from "camelcase-keys";
import { parseISO } from "date-fns";
import dayjs from "dayjs";
import { isEmpty, isNil } from "lodash";
import snakeCaseKeys from "snakecase-keys";

import { ListResponseData, MetaPagination, Param } from "../types";
import client from "./client";

const ISODateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
  return typeof value === "string" && ISODateFormat.test(value);
};

export const handleDates = (data: unknown) => {
  if (isIsoDateString(data)) return parseISO(data);
  if (typeof data === "string" && dayjs(data, "YYYY-MM-DD", true).isValid())
    return dayjs(data).toDate();
  if (data === null || data === undefined || typeof data !== "object")
    return data;

  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (isIsoDateString(val)) data[key] = parseISO(val);
    else if (
      typeof val === "string" &&
      dayjs(val, "YYYY-MM-DD", true).isValid()
    )
      // @ts-expect-error this is a hack to make the type checker happy
      data[key] = dayjs(val).toDate();
    else if (typeof val === "object") handleDates(val);
  }

  return data;
};

export const convertDatesToStrings = (data: unknown) => {
  if (data instanceof Date) return dayjs(data).format("YYYY-MM-DD");
  if (data === null || data === undefined || typeof data !== "object")
    return data;
  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (val instanceof Date) data[key] = dayjs(val).format("YYYY-MM-DD");
    else if (typeof val === "object") handleDates(val);
  }

  return data;
};

export async function handleDetail<Type>({
  id,
  baseUrl,
}: {
  id?: number | string;
  baseUrl: string;
}): Promise<Type> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data = await client.get(`${baseUrl}/${id}`).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

export async function handleSave<Type>({
  baseUrl,
  input,
}: {
  baseUrl: string;
  input: any;
}): Promise<Type> {
  try {
    // const inputWithCorrectDates: Record<string, unknown> =
    //   convertDatesToStrings(input);
    const inputSnaked: Record<string, unknown> = snakeCaseKeys(input, {
      deep: true,
    });
    let data: Type;
    if (typeof input.id === "undefined") {
      data = await client.post(baseUrl, { json: inputSnaked }).json();
    } else {
      data = await client
        .put(`${baseUrl}/${input.id}`, { json: inputSnaked })
        .json();
    }
    const parsedData = handleDates(data);
    return camelcaseKeys(parsedData as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

export async function handleSimpleList<Type>({
  url,
  params,
}: {
  url: string;
  params?: Param[];
}): Promise<Type[]> {
  try {
    const searchParams = new URLSearchParams();
    if (params) {
      params.forEach(({ key, value }) => {
        searchParams.set(key, value);
      });
    }
    const data: Record<string, unknown>[] = await client
      .get(url, { searchParams })
      .json();
    return camelcaseKeys(data, {
      deep: true,
    }) as Type[];
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

export async function handleList<Type>({
  baseUrl,
  params,
}: {
  baseUrl: string;
  params?: Param[];
}): Promise<ListResponseData<Type>> {
  try {
    const searchParams = new URLSearchParams();
    if (params && !isEmpty(params)) {
      params.forEach(param => {
        if (!isNil(param["value"])) {
          searchParams.set(param["key"], param["value"]);
        }
      });
    }

    const response: { data: Type[]; meta: MetaPagination } = await client
      .get(baseUrl, {
        searchParams,
      })
      .json();
    const parsedData = response.data
      ? response.data.map(obj => handleDates(obj))
      : [];
    return camelcaseKeys(
      {
        data: parsedData || ([] as Type[]),
        meta: response.meta as MetaPagination,
      },
      { deep: true }
    ) as ListResponseData<Type>;
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

export const handleInfiniteList =
  <Type>({ baseUrl, params }: { baseUrl: string; params?: Param[] }) =>
  async ({
    pageParam,
  }: {
    pageParam?: number;
  }): Promise<{ data: Type[]; meta: MetaPagination }> => {
    try {
      const searchParams = new URLSearchParams();
      if (params && !isEmpty(params)) {
        params.forEach(param => {
          if (!isNil(param["value"])) {
            searchParams.set(param["key"], param["value"]);
          }
        });
      }
      searchParams.set("page", `${pageParam || 1}`);

      const response: { data: Type[]; meta: MetaPagination } = await client
        .get(baseUrl, {
          searchParams,
        })
        .json();
      return camelcaseKeys(
        {
          data: response.data || ([] as Type[]),
          meta: response?.meta as MetaPagination,
        },
        { deep: true }
      ) as { data: Type[]; meta: MetaPagination };
    } catch (error) {
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  };

export async function handleDelete<Type>({
  baseUrl,
  id,
}: {
  baseUrl: string;
  id?: number | string;
}): Promise<Type> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: Type = await client.delete(`${baseUrl}/${id}`).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

export async function handleBasicDelete({
  url,
  params,
}: {
  url: string;
  params: Param[];
}): Promise<any> {
  try {
    const searchParams = new URLSearchParams();
    if (params && !isEmpty(params)) {
      params.forEach(param => {
        if (!isNil(param["value"])) {
          searchParams.set(param["key"], param["value"]);
        }
      });
    }
    const data = await client.delete(url, { searchParams }).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    });
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

export async function handleBasicPost({
  url,
  input,
}: {
  url: string;
  input?: any;
}): Promise<any> {
  try {
    const inputSnaked = snakeCaseKeys(input || {}, {
      deep: true,
    });
    const data = await client.post(url, { json: inputSnaked }).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    });
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

export async function handleImageUpload({
  url,
  image
}: {
  url: string;
  image: File | Blob;
}): Promise<any> {
  try {
    const formData = new FormData()
    formData.append('image', image)
    return await client.post(url, {
      body: formData
    })
  } catch(error){
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}

export async function handleBasicGet<Type>({
  url,
  params,
}: {
  url: string;
  params?: Param[];
}): Promise<Type> {
  try {
    const searchParams = new URLSearchParams();
    if (params && !isEmpty(params)) {
      params.forEach(param => {
        if (!isNil(param["value"])) {
          searchParams.set(param["key"], param["value"]);
        }
      });
    }

    const data = await client
      .get(url, {
        searchParams,
      })
      .json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
}
