import React from "react";
import * as Sentry from "@sentry/react";
import {
  Commodity,
  EvaluableType,
  FieldVariety,
  TrialVariety,
} from "../../types";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  InputAdornment,
  OutlinedInput,
  Grid,
} from "@mui/material";
import CommodityApi from "../../api/Commodity.api";
import Evaluations from "./Evaluations";
import { SelectChangeEvent } from "@mui/material/Select";
import MultiEvaluations from "./MultiEvaluations";
import MeasurementEvaluations from './MeasurementEvaluations'
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { isNil } from "lodash";
import EvaluationImages from "./EvaluationImages";
import EvaluationScore from './EvaluationScore';
import toast from "react-hot-toast";
import { EvaluationProvider } from '../../contexts/Evaluation.context'
import { useDebounce } from "../../utils";
import { useTrialContext } from '../../contexts/Trial.context'

export default function SelectedTypeVariety({
  typeVariety,
  evaluableType,
  handleClose,
  useSave,
  children,
}: {
  typeVariety: FieldVariety | TrialVariety;
  evaluableType: EvaluableType;
  useSave: () => any;
  handleClose: () => void;
  children?: string | JSX.Element | JSX.Element[] | undefined;
}) {
  const {
    setSelectedFieldVariety,
    setSelectedTrialVariety,
  } = useTrialContext();

  const [typeVarietyId, setTypeVarietyId] = React.useState<number | undefined>(undefined)
  const [comments, setComments] = React.useState<string>(
    typeVariety.comments || ""
  );
  const [growerComments, setGrowerComments] = React.useState<string>(
    typeVariety.growerComments || ""
  );
  const [growerLike, setGrowerLike] = React.useState<boolean | undefined>(
    typeVariety.growerLike || undefined
  );
  const [maturityDate, setMaturityDate] = React.useState<Dayjs | null>(
    typeVariety.maturityDate
      ? dayjs(typeVariety.maturityDate, "YYYY-MM-DD")
      : null
  );

  const [selectedCommodity, setSelectedCommodity] = React.useState<
    Commodity | undefined
  >(typeVariety?.commodity || undefined);

  const { data: commodities } = CommodityApi.useByVariety(
    typeVariety.variety.id
  );

  React.useEffect(() => {
    if(typeVariety?.id !== typeVarietyId){
      setTypeVarietyId(typeVariety?.id)
      setSelectedCommodity(typeVariety?.commodity);
      setComments(typeVariety.comments || "");
      setGrowerComments(typeVariety.growerComments || "");
      setGrowerLike(typeVariety?.growerLike);
      setMaturityDate(
        typeVariety.maturityDate
          ? dayjs(typeVariety.maturityDate)
          : null
      );
    }
  }, [typeVarietyId, typeVariety, typeVariety?.commodity]);

  const { mutateAsync: saveTypeVariety } = useSave();

  const handleCommodityChange = async (event: SelectChangeEvent<string>) => {
    const newSelectedCommodityId: number | undefined = event.target.value
      ? parseInt(event.target.value)
      : undefined;
    const newCommodity =
      commodities &&
      commodities.find(commodity => newSelectedCommodityId === commodity.id);
    setSelectedCommodity(newCommodity);
    try {
      const newTypeVariety = await saveTypeVariety({
        ...typeVariety,
        commodityId: newCommodity?.id,
      });
      if(evaluableType === 'trial_variety'){
        setSelectedTrialVariety && setSelectedTrialVariety(newTypeVariety)
      } else {
        setSelectedFieldVariety && setSelectedFieldVariety(newTypeVariety)
      }
      toast.success("Successfully saved commodity selection");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error("Failed to save.");
    }
  };

  const debouncedSendRequest = useDebounce(async () => {
    try {
      const newTypeVariety = await saveTypeVariety({
        ...typeVariety,
        comments,
        growerComments,
        growerLike,
        maturityDate: maturityDate?.format("YYYY-MM-DD"),
      });

      if(evaluableType === 'trial_variety'){
        setSelectedTrialVariety && setSelectedTrialVariety(newTypeVariety)
      } else {
        setSelectedFieldVariety && setSelectedFieldVariety(newTypeVariety)
      }
      toast.success("Successfully saved.");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error("Failed to save.");
    }
  })

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        {children}
        <Box>
          {commodities && (
            <FormControl fullWidth>
              <InputLabel id="select-field-variety-commodity-label">
                Commodity
              </InputLabel>
              <Select
                fullWidth
                labelId="select-field-variety-commodity-label"
                id="select-field-variety-commodity"
                label={"Commodity"}
                value={selectedCommodity?.id?.toString() || ""}
                onChange={handleCommodityChange}
              >
                <MenuItem value={undefined}></MenuItem>
                {commodities &&
                  commodities.map((commodity: Commodity) => (
                    <MenuItem
                      value={commodity.id?.toString()}
                      key={commodity.id}
                    >
                      {commodity.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box>
          {/* show all the evaluations for the field variety */}
          {selectedCommodity ? (
            <EvaluationProvider
              commodityId={selectedCommodity.id}
              evaluableType={evaluableType}
              evaluableId={typeVariety.id}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Grid container spacing={2}>
                  <Evaluations
                    commodity={selectedCommodity}
                    evaluableId={typeVariety.id}
                    evaluableType={evaluableType}
                  />
                  <MultiEvaluations
                    commodity={selectedCommodity}
                    multiEvaluableId={typeVariety.id}
                    multiEvaluableType={evaluableType}
                  />
                  <Grid item xs={12} sm={6} md={4}>
                    <DatePicker
                      label="Maturity Date"
                      value={maturityDate}
                      onChange={(newValue: Dayjs | null) => {
                        setMaturityDate(newValue)
                        debouncedSendRequest()
                      }}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <MeasurementEvaluations
                    commodity={selectedCommodity}
                    evaluableId={typeVariety.id}
                    evaluableType={evaluableType}
                  />
                </Grid>
                <FormControl fullWidth variant={"outlined"}>
                  <InputLabel htmlFor="outlined-adornment">Comments</InputLabel>
                  <OutlinedInput
                    fullWidth
                    label="Comments"
                    multiline
                    rows={4}
                    value={comments}
                    onChange={event => {
                      setComments(event.target.value)
                      debouncedSendRequest()
                    }}
                    inputProps={{ maxLength: 350 }}
                    endAdornment={
                      <InputAdornment position="end">{`${
                        (comments || "").length
                      }/350`}</InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant={"outlined"}>
                  <InputLabel htmlFor="outlined-adornment">
                    Grower Comments
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    label="Grower Comments"
                    multiline
                    rows={4}
                    value={growerComments}
                    onChange={event => {
                      setGrowerComments(event.target.value)
                      debouncedSendRequest()
                    }}
                    inputProps={{ maxLength: 350 }}
                    endAdornment={
                      <InputAdornment position="end">{`${
                        (growerComments || "").length
                      }/350`}</InputAdornment>
                    }
                  />
                </FormControl>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant={"body1"}>
                      Grower's Overall Felling
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      variant={
                        !isNil(growerLike) && growerLike
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setGrowerLike(true)
                        debouncedSendRequest()
                      }}
                      startIcon={<ThumbUpIcon />}
                    >
                      Like
                    </Button>
                    <Button
                      variant={
                        !isNil(growerLike) && !growerLike
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setGrowerLike(false)
                        debouncedSendRequest()
                      }}
                      startIcon={<ThumbDownIcon />}
                    >
                      Dislike
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                  <EvaluationScore typeVariety={typeVariety} />
                </Box>
                <EvaluationImages
                  evaluableType={evaluableType}
                  typeVariety={typeVariety}
                />
              </Box>
            </EvaluationProvider>
          ) : (
            <Typography>Must select a commodity in order to proceed.</Typography>
          )}
        </Box>
      </CardContent>
      <CardActions>
        <Button variant={"contained"} fullWidth onClick={debouncedSendRequest}>
          Save
        </Button>
        <Button
          variant={"contained"}
          fullWidth
          color={"info"}
          onClick={handleClose}
        >
          Close
        </Button>
      </CardActions>
    </Card>
  );
}
