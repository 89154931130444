import { Box, Button, Typography, CircularProgress } from "@mui/material";
import Grid from '@mui/material/Grid2'
import { useReportCompareContext } from "../../contexts/ReportCompare.context";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";

export default function ReportCompareHeader() {
  const { mainVarieties, isLoading } = useReportCompareContext();
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6 }}>
        {mainVarieties &&
          Object.values(mainVarieties).map(variety => (
            <Typography key={variety.id} variant={"h3"}>
              {variety.name}
            </Typography>
          ))}
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button variant={"contained"} color={"primary"} size={"large"}>
            <EmailIcon />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button variant={"contained"} color={"primary"} size={"large"}>
            <DescriptionIcon />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button variant={"contained"} color={"primary"} size={"large"}>
            <PrintIcon />
          </Button>
        </Box>
      </Grid>
      {isLoading && (
        <Grid size={{ xs: 12}}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <CircularProgress size={80} thickness={7} color={"primary"} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
