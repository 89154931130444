import { liteClient } from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import AlgoliaTrialList from "../components/AlgoliaTrialList";
import AlgoliaTrialSearchBox from "../components/AlgoliaTrialSearchBox";
import React from 'react'
import { SortDirection } from '../types'
import { Dayjs } from "dayjs";
import usePersistState from '../usePersistState'

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function TrialList() {
  const [excludeYears, setExcludeYears] = usePersistState("excludeYears", false);
  const [orderBy, setOrderBy] = usePersistState("orderBy", "harvestDate")
  const [orderDirection, setOrderDirection] = usePersistState<SortDirection>("sortDirection", "desc")

  const [mexico, setMexico] = usePersistState("mexic", true);
  const [archived, setArchived] = usePersistState("archived", false);
  const [wetDateStart, setWetDateStart] = React.useState<Dayjs | null>(null);
  const [wetDateEnd, setWetDateEnd] = React.useState<Dayjs | null>(null);
  const [harvestDateStart, setHarvestDateStart] = React.useState<Dayjs | null>(
    null
  );
  const [harvestDateEnd, setHarvestDateEnd] = React.useState<Dayjs | null>(
    null
  );

  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={`Trial_${process.env.REACT_APP_ENV}`}
      >
        <AlgoliaTrialSearchBox
          excludeYears={excludeYears}
          setExcludeYears={setExcludeYears}
          orderBy={orderBy}
          orderDirection={orderDirection}
          mexico={mexico}
          setMexico={setMexico}
          archived={archived}
          setArchived={setArchived}
          wetDateStart={wetDateStart}
          setWetDateStart={setWetDateStart}
          wetDateEnd={wetDateEnd}
          setWetDateEnd={setWetDateEnd}
          harvestDateStart={harvestDateStart}
          setHarvestDateStart={setHarvestDateStart}
          harvestDateEnd={harvestDateEnd}
          setHarvestDateEnd={setHarvestDateEnd}
        />
        <AlgoliaTrialList
          excludeYears={excludeYears}
          setExcludeYears={setExcludeYears}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          mexico={mexico}
          archived={archived}
          wetDateStart={wetDateStart}
          wetDateEnd={wetDateEnd}
          harvestDateStart={harvestDateStart}
          harvestDateEnd={harvestDateEnd}
        />
      </InstantSearch>
    </>
  );
}
