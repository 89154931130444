import { red } from "@mui/material/colors";
import { Box, IconButton } from "@mui/material";

export default function PlotGridLayout({
  rows,
  selectedPlotBeds,
  selectPlotBed,
}: {
  rows: any[];
  selectedPlotBeds: { [key: string]: { row: number; col: number } };
  selectPlotBed: (arg0: number, arg1: number) => void;
}) {
  return (
    <Box sx={{ overflowX: 'scroll', display: "flex", flexDirection: "column" }}>
      {rows.map((cols, rowIndex) => (
        <Box component="div" sx={{  display: "flex" }} key={rowIndex}>
          {cols.map((plot: any, colIndex: number) => (
            <IconButton
              key={plot.key}
              aria-label="Set Variety"
              sx={{ padding: "7px" }}
              onClick={() => selectPlotBed(plot.rowIndex, plot.colIndex)}
            >
              <CircleIcon
                key={plot.key}
                selected={selectedPlotBeds[plot.key] !== undefined}
                value={plot.plotNumber ? plot.plotNumber : "-"}
              />
            </IconButton>
          ))}
        </Box>
      ))}
    </Box>
  );
}

function CircleIcon({ value, selected }: { value: string; selected: boolean }) {
  return (
    <Box
      sx={{
        borderRadius: "0.4em",
        display: "inline-block",
        lineHeight: "1.4em",
        textAlign: "center",
        width: "1.4em",
        color: "white",
        fontSize: "2em",
        background: selected ? red[800] : "rgba(3, 67, 144, 1)",
        "&:hover": {
          background: red[800],
        },
      }}
    >
      {value}
    </Box>
  );
}
