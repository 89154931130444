import React from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import {
  APIProvider,
  Map,
  MapCameraChangedEvent,
  MapMouseEvent,
  Marker,
  AdvancedMarker,
  Pin
} from "@vis.gl/react-google-maps";
import { isEmpty } from "lodash";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyAlj54uL_IvFLVYgwkFb1i_VNX7aiMJvek";

const liveLocationOptions = {
  enableHighAccuracy: true,
  maximumAge: 0,
};

export default function FarmMap({
  lat,
  lng,
  zoom,
  setLat,
  setLng,
  setZoom,
}: {
  lat: string;
  lng: string;
  zoom: number;
  setLat: React.Dispatch<React.SetStateAction<string>>;
  setLng: React.Dispatch<React.SetStateAction<string>>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [locked, setLocked] = React.useState<boolean>(
    !(isEmpty(lat) || isEmpty(lng))
  );
  const [, setError] = React.useState<string>("");

  const [latLive, setLatLive] = React.useState<string | undefined>(undefined)
  const [lngLive, setLngLive] = React.useState<string | undefined>(undefined)

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLngLive(position.coords.longitude.toString())
          setLatLive(position.coords.latitude.toString())
        },
        error => setError("Not able to access GPS on device"),
        liveLocationOptions
      );
    }
  }, [])

  const refreshGeoLocation = React.useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          if (!locked) {
            setLat(position.coords.latitude.toString());
            setLng(position.coords.longitude.toString());
          }
          setLngLive(position.coords.longitude.toString())
          setLatLive(position.coords.latitude.toString())
        },
        error => setError("Not able to access GPS on device"),
        liveLocationOptions
      );
    } else {
      setError("Not able to access GPS on device");
    }
  }, [locked, setLat, setLng, setError]);

  React.useEffect(() => {
    if (isEmpty(lat) && isEmpty(lng)) {
      refreshGeoLocation();
    }
  }, [lat, lng, refreshGeoLocation]);

  const toggleLock = () => {
    setLocked(l => !l);
  };

  const handleClick = (ev: MapMouseEvent) => {
    if (!locked && ev.detail.latLng?.lng && ev.detail.latLng?.lat) {
      setLat(ev.detail.latLng.lat.toString());
      setLng(ev.detail.latLng.lng.toString());
    }
  };

  const onCenterChanged = (ev: MapCameraChangedEvent) => {
    if (!locked) {
      setLat(ev.detail.center.lat.toString());
      setLng(ev.detail.center.lng.toString());
    }
  };

  const onZoomChange = (ev: MapCameraChangedEvent) => {
    setZoom(ev.detail.zoom);
  };

  console.log('live', latLive, lngLive)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={"Latitude"}
          variant="outlined"
          value={lat}
          onChange={event => setLat(event.target.value)}
          disabled={locked}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={"Longitude"}
          variant="outlined"
          value={lng}
          onChange={event => setLng(event.target.value)}
          disabled={locked}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Button
          size={"large"}
          variant={"contained"}
          color={"info"}
          fullWidth
          onClick={refreshGeoLocation}
          disabled={locked}
        >
          Live
        </Button>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Button
          size={"large"}
          variant={"contained"}
          color={"info"}
          fullWidth
          onClick={toggleLock}
        >
          {locked ? "Unlock" : "Lock"}
        </Button>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <a
          href={`http://maps.google.com/?q=${lat},${lng}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button size="large" variant="contained" color="info" fullWidth>
            Directions
          </Button>
        </a>
      </Grid>
      <Grid item xs={12}>
        {lat && lng ? (
          <APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={["marker", "places", "localContext"]}>
            <Map
              style={{ width: "auto", height: "30rem" }}
              defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
              // center={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
              zoom={zoom}
              gestureHandling={"greedy"}
              disableDefaultUI={false}
              onClick={handleClick}
              onZoomChanged={onZoomChange}
              onCenterChanged={onCenterChanged}
              mapId={"1caad0ad79139b54"}
              mapTypeId={"hybrid"} //terrain, hybrid, roadmap, satellite
              reuseMaps={true}
            >
              {/* Child components, such as markers, info windows, etc. */}
              <Marker
                position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                clickable={true}
                onClick={() => alert("marker was clicked!")}
                title={"clickable google.maps.Marker"}
              />
              {latLive && lngLive && (
                <AdvancedMarker
                  position={{ lat: parseFloat(latLive), lng: parseFloat(lngLive) }}
                >
                  <Pin background={'#0000FF'} glyphColor={'#0000FF'} borderColor={'#0000FF'} />
                </AdvancedMarker>
              )}
            </Map>
          </APIProvider>
        ) : (
          <Typography gutterBottom variant="h6">
            Map currently not set
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
