import React from "react";
import * as Sentry from "@sentry/react";
import { useParams } from "react-router-dom";
import TrialApi from "../api/Trial.api";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import ReportHeader from "../components/trials/ReportHeader";
import { getVarietyPlotNumber } from "../utils";
import TrialVarietyApi from "../api/TrialVariety.api";
import PlotBedApi from "../api/PlotBed.api";
import { PlotBed } from "../types";
import FieldVarietyApi from "../api/FieldVariety.api";
import EvaluationReportColumn from "../components/trials/EvaluationReportColumn";
import ReportStatus from "../components/ReportStatus";
import toast from "react-hot-toast";
import EmailDrawer from "../components/trials/EmailDrawer";
import { isNil } from 'lodash'

export default function TrialEvaluationReport() {
  const { id } = useParams<{ id: string }>();
  const [reportId, setReportId] = React.useState<number | undefined>(undefined);
  const [openEmailDrawer, setOpenEmailDrawer] = React.useState<boolean>(false);
  const { data: trial } = TrialApi.useDetail(id);
  const { data: fieldVarieties } = FieldVarietyApi.useList(id);
  const { data: trialVarieties } = TrialVarietyApi.useList(id);
  const { data: plotBeds } = PlotBedApi.useList({
    parentId: id,
    parentType: "trial",
  });
  // const { data: suppliers } =
  //   CompanyApi.useSuppliersByTrialVarietiesAttachedToTrial({ trialId: id });

  const [supplierId, setSupplierId] = React.useState<number | undefined>(
    undefined
  );

  const { mutateAsync: sendEvaluationReportEmail } =
    TrialApi.useSendEvaluationEmail({
      trialId: id,
      supplierId,
    });

  const { mutateAsync: printEvaluationReport } =
    TrialApi.useGenerateEvaluationReport({
      trialId: id,
    });

  const { mutateAsync: printEvaluationReportExcel } =
    TrialApi.useGenerateEvaluationReportExcel({
      trialId: id,
    });

  if (!trial || !trialVarieties || !fieldVarieties || !plotBeds) {
    return <div>Loading...</div>;
  }

  const handleExcel = async () => {
    try {
      const result = await printEvaluationReportExcel({
        supplierId,
      });
      setReportId(result.id);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error("Failed to print Evaluation Report Excel");
    }
  };

  const handlePrint = async () => {
    try {
      const result = await printEvaluationReport({
        supplierId,
      });
      setReportId(result.id);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error("Failed to print Evaluation Report");
    }
  };

  const plotBedsByKey: { [key: string]: PlotBed } = {};
  plotBeds.forEach(plotBed => {
    plotBedsByKey[plotBed.key] = plotBed;
  });

  const plotNumbers = getVarietyPlotNumber({
    plotBeds: plotBedsByKey,
    plotType: trial.plotType,
    rows: trial.plotRows,
    cols: trial.plotCols,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant={"h2"}>Evaluation Report</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Button
              onClick={() => setOpenEmailDrawer(true)}
              variant="contained"
              color={"primary"}
              size={"large"}
              fullWidth
            >
              <EmailIcon />
            </Button>
            <EmailDrawer
              showDrawer={openEmailDrawer}
              handleClose={() => setOpenEmailDrawer(false)}
              trial={trial}
              supplierId={supplierId}
              sendEmail={sendEvaluationReportEmail}
              autoEmailKey={"auto_send_evaluation_report"}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Button
              onClick={handleExcel}
              variant="contained"
              color={"primary"}
              size={"large"}
              fullWidth
            >
              <DescriptionIcon />
            </Button>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Button
              onClick={handlePrint}
              variant="contained"
              color={"primary"}
              size={"large"}
              fullWidth
            >
              <PrintIcon />
            </Button>
          </Box>
        </Box>
      </Grid>
      {reportId && (
        <Grid item xs={12}>
          <ReportStatus reportId={reportId} />
        </Grid>
      )}
      <Grid item xs={12}>
        <ReportHeader
          trial={trial}
          supplierId={supplierId}
          setSupplierId={setSupplierId}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          direction="row"
          sx={{ alignItems: "stretch" }}
        >
          {fieldVarieties
            .filter(fieldVariety => fieldVariety.commodityId)
            .map(fieldVariety => (
              <Grid item xs={12} sm={6} md={4} key={fieldVariety.id}>
                <EvaluationReportColumn
                  key={fieldVariety.id}
                  typeVariety={fieldVariety}
                  evaluableType={"field_variety"}
                  showGrowerComments={isNil(supplierId)}
                />
              </Grid>
            ))}
          {trialVarieties
            .filter(trialVariety =>
              supplierId ? trialVariety.variety.companyId === supplierId : true
            )
            .sort((a, b) => plotNumbers[a.id] - plotNumbers[b.id])
            .map(trialVariety => (
              <Grid item xs={12} sm={6} md={4} key={trialVariety.id}>
                <EvaluationReportColumn
                  key={trialVariety.id}
                  typeVariety={trialVariety}
                  evaluableType={"trial_variety"}
                  plotNumber={plotNumbers[trialVariety.id]}
                  fieldVarieties={fieldVarieties}
                  showGrowerComments={isNil(supplierId)}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
