import { useInfiniteHits, Highlight, useSearchBox } from "react-instantsearch";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  Box,
  Button,
  IconButton,
  TableContainer
} from "@mui/material";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useSession } from "../contexts/Session.context";
import UserApi from "../api/User.api";
import TrialApi from "../api/Trial.api"
import { User, SortDirection, Trial } from '../types'
import { sortByKeyComparitor, sortByKeyComparitorExcludeYear } from '../utils'
import { isNil, isEmpty } from 'lodash'
import dayjs from 'dayjs'

export default function AlgoliaTrialList(props: any) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { session } = useSession();
  const { query } = useSearchBox(props);
  const { data: user } = UserApi.useDetail(session && session?.userId);
  const {
    items,
    isLastPage,
    showMore
  } = useInfiniteHits(props);
  const {
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    mexico,
    archived,
    excludeYears,
    personalized,
    wetDateStart,
    wetDateEnd,
    harvestDateStart,
    harvestDateEnd,
  } = props;
  const sentinelRef = React.useRef(null);

  const containsQueryStr = !(isNil(query) || isEmpty(query))

  const { data: trialsData, refetch, fetchNextPage, hasNextPage } = TrialApi.useInfiniteList({
    orderBy,
    orderDirection,
    excludeYears,
    mexico,
    archived,
    personalized,
    wetDateStart,
    wetDateEnd,
    harvestDateStart,
    harvestDateEnd,
    enabled: !containsQueryStr
  })

  const sortTable = (trials: any[]) => {
    const excludeYear = props.excludeYears
    let comparitor = (a: any, b: any) => sortByKeyComparitor(orderBy, a, b, orderDirection);
    if (excludeYear) {
      comparitor = (a: any, b: any) =>
        sortByKeyComparitorExcludeYear(orderBy, a, b, orderDirection);
    }
    return trials.sort(comparitor);
  };

  React.useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          // if (entry.isIntersecting && !isLastPage) {
          if (!isLastPage) {
            // Load more hits
            showMore();
          }
          if(hasNextPage){
            fetchNextPage()
          }
        });
      }, { root: null, threshold: 0.01 });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }

  }, [isLastPage, showMore, hasNextPage, fetchNextPage]);

  React.useEffect(() => {
    refetch();
  }, [orderBy, orderDirection, refetch]);

  return (
    <Grid item xs={12} sx={{ overflow: "scroll", maxWidth: "98vw", mt: "1.5rem", mb: "0.5rem" }}>
      <TableContainer>
        <Table size="small" padding="none">
          <TableHead>
            <TableRow>
              { isSmallScreen && (
                <TableCell></TableCell>
              )}
              <TableCell sortDirection={orderDirection}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderDirection}
                  onClick={() => {
                    setOrderBy("name")
                    setOrderDirection((od: SortDirection) => od === "asc" ? "desc" : "asc")
                  }}
                >
                  <Typography variant="subtitle2" >
                    Name
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">
                  Commodity
                </Typography>
              </TableCell>
              { !isSmallScreen && (
                <>
                  <TableCell sortDirection={orderDirection}>
                    <TableSortLabel
                      active={orderBy === 'wetDate'}
                      direction={orderDirection}
                      onClick={() => {
                        setOrderBy("wetDate")
                        setOrderDirection((od: SortDirection) => od === "asc" ? "desc" : "asc")
                      }}
                    >
                      <Typography variant="subtitle2">
                        Wet Date
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={orderDirection}
                  >
                    <TableSortLabel
                      active={orderBy === 'harvestDate'}
                      direction={orderDirection}
                      onClick={() => {
                        setOrderBy("harvestDate")
                        setOrderDirection((od: SortDirection) => od === "asc" ? "desc" : "asc")
                      }}
                    >
                      <Typography variant="subtitle2">
                        Est. Harvest Date
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                </>
              )}
              <TableCell>
                <Typography variant="subtitle2">
                  Grower
                </Typography>
              </TableCell>
              { !isSmallScreen && (
                <TableCell>
                  <Typography variant="subtitle2">
                    Region
                  </Typography>
                </TableCell>
              )}
              <TableCell>
                <Typography variant="subtitle2">
                  Area
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">
                  Ranch
                </Typography>
              </TableCell>
              {!isSmallScreen && user && !user.hideExtraTrialInfo && (
                <React.Fragment>
                  <TableCell align="center">
                    <Typography variant="subtitle2">
                      Map Printed
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle2">
                      Producer Map Emailed
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle2">
                      Grower Map Emailed
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle2">
                      Cleaned
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle2" >
                      Evaluation Ready
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle2" >
                      Producer Evaluation Emailed
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle2" >
                      Grower Evaluation Emailed
                    </Typography>
                  </TableCell>
                </React.Fragment>
              )}
              { !isSmallScreen && (
                <TableCell align="center">
                  <Typography variant="subtitle2" >
                    Trial Type
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {containsQueryStr ? (
              <React.Fragment>
                {sortTable(items).map((row: any) => (
                  <AlgoliaTrialRow key={row.objectID} user={user} row={row} />
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {trialsData && trialsData?.pages.map((group: any, i: number) => (
                  <React.Fragment key={i}>
                    {group.data.map((trial: Trial) => (
                      <TrialRow key={trial.id} row={trial} user={user} />
                    ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ width: "100%" }}>
        {isMediumScreen ? (
          <React.Fragment>
            {containsQueryStr ? (
              <React.Fragment>
                {!isLastPage && (
                  <Box sx={{ width: '100%' }}>
                    <Button fullWidth onClick={() => showMore()}>
                      Show More
                    </Button>
                  </Box>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {hasNextPage && (
                  <Box sx={{ width: '100%' }}>
                    <Button fullWidth onClick={() => fetchNextPage()}>
                      Show More
                    </Button>
                  </Box>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <div ref={sentinelRef} aria-hidden="true" id="scrollArea" />
        )}
      </Box>
    </Grid>
  );
}

function TrialRow({ user, row }: { user?: User, row: Trial }){
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
        { isSmallScreen ? (
          <>
            <TableCell>
              <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(o => !o)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell>
              <Link to={`/trials/${row?.id}`}>
                <Typography variant="body2" >
                  {row.name}
                </Typography>
              </Link>
            </TableCell>
          </>
        ) : (
          <TableCell>
            <Link to={`/trials/${row?.id}`}>
              <Typography variant="body2" >
                {row.name}
              </Typography>
            </Link>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2" >
            {row?.commodity?.name}
          </Typography>
        </TableCell>
        { !isSmallScreen && (
          <>
            <TableCell>
              <Typography variant="body2" >
              {row.wetDate ? dayjs(row.wetDate).format("YYYY-MM-DD") : "" }
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" >
                {row.estHarvestDate ? dayjs(row.estHarvestDate).format("YYYY-MM-DD") : ""}
              </Typography>
            </TableCell>
          </>
        )}
        <TableCell>
          <Typography variant="body2" >
            {row?.grower?.name}
          </Typography>
        </TableCell>
        { !isSmallScreen && (
          <TableCell>
            <Typography variant="body2" >
              {row?.region?.name}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2" >
            {row?.area?.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" >
            {row?.ranch?.name}
          </Typography>
        </TableCell>
        {!isSmallScreen && user && !user.hideExtraTrialInfo && (
          <React.Fragment>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.mapPrinted ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.producerMapEmailed ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.growerMapEmailed ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.cleaned ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.evaluationReady ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.producerEvaluationEmailed ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.growerEvaluationEmailed ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
          </React.Fragment>
        )}
        { !isSmallScreen && (
          <TableCell>
            <Typography variant="body2" >
              {row.salesTeam ? "Sales Trial" : "PD Trial"}
            </Typography>
          </TableCell>
        )}
      </TableRow>
      { isSmallScreen && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ mb: "2rem", ml: 1, mr: 1 }}>
                <Typography variant="h6"  component="div">
                 Details of {row?.name}
                </Typography>
                <Table size="small" aria-label="details">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2" >
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" >
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Wet Date
                          </Box>
                          <Box sx={{flex: 1}}>
                            {dayjs(row.wetDate).format("YYYY-MM-DD")}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Est Harvest Date
                          </Box>
                          <Box sx={{flex: 1}}>
                            {dayjs(row.estHarvestDate).format("YYYY-MM-DD")}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Grower
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row?.grower?.name}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Region
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row?.region?.name}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Area
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row?.area?.name}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Ranch
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row?.ranch?.name}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    { user && !user.hideExtraTrialInfo && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Map Printed
                              </Box>
                              <Box sx={{flex: 1 }}>
                                {row.mapPrinted ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Producer Map Emailed
                              </Box>
                              <Box sx={{flex: 1 }}>
                                {row.producerMapEmailed ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell >
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Grower Map Emailed
                              </Box>
                              <Box sx={{flex: 1 }}>
                                {row.growerMapEmailed ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Cleaned
                              </Box>
                              <Box sx={{flex: 1}}>
                                {row.cleaned ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Evaluation Ready
                              </Box>
                              <Box sx={{flex: 1}}>
                                {row.evaluationReady ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Producer Evaluation Emailed
                              </Box>
                              <Box sx={{flex: 1}}>
                                {row.producerEvaluationEmailed ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Grower Evaluation Emailed
                              </Box>
                              <Box sx={{flex: 1}}>
                                {row.growerEvaluationEmailed ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                     <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Trial Type
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row.salesTeam ? "Sales Trial" : "PD Trial"}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

// row: Hit<TrialAlgolia>
function AlgoliaTrialRow({ user, row }: { user?: User, row: any }){
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow key={row.objectID} sx={{ '& > *': { borderBottom: 'unset' } }}>
        { isSmallScreen ? (
          <>
            <TableCell>
              <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(o => !o)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell>
              <Link to={`/trials/${row?.objectID}`}>
                <Typography variant="body2" >
                  <Highlight attribute={"name"} hit={row} />
                </Typography>
              </Link>
            </TableCell>
          </>
        ) : (
          <TableCell>
            <Link to={`/trials/${row?.objectID}`}>
              <Typography variant="body2" >
                <Highlight attribute={"name"} hit={row} />
              </Typography>
            </Link>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2" >
            {row?.commodity?.name}
          </Typography>
        </TableCell>
        { !isSmallScreen && (
          <>
            <TableCell>
              <Typography variant="body2" >
                {row.wet_date}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" >
                {row.harvest_date}
              </Typography>
            </TableCell>
          </>
        )}
        <TableCell>
          <Typography variant="body2" >
            {row?.grower?.name}
          </Typography>
        </TableCell>
        { !isSmallScreen && (
          <TableCell>
            <Typography variant="body2" >
              {row?.region?.name}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2" >
            {row?.area?.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" >
            {row?.ranch?.combined}
          </Typography>
        </TableCell>
        {!isSmallScreen && user && !user.hideExtraTrialInfo && (
          <React.Fragment>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.map_printed ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.producer_map_emailed ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.grower_map_emailed ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.cleaned ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.evaluation_ready ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.producer_evaluation_emailed ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" >
                {row.grower_evaluation_emailed ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Typography>
            </TableCell>
          </React.Fragment>
        )}
        { !isSmallScreen && (
          <TableCell>
            <Typography variant="body2" >
              {row.sales_team ? "Sales Trial" : "PD Trial"}
            </Typography>
          </TableCell>
        )}
      </TableRow>
      { isSmallScreen && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ mb: "2rem", ml: 1, mr: 1 }}>
                <Typography variant="h6"  component="div">
                 Details of {row?.name}
                </Typography>
                <Table size="small" aria-label="details">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2" >
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" >
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Wet Date
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row.wet_date}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Est Harvest Date
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row.harvest_date}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Grower
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row?.grower?.name}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Region
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row?.region?.name}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Area
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row?.area?.name}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Ranch
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row?.ranch?.combined}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    { user && !user.hideExtraTrialInfo && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Map Printed
                              </Box>
                              <Box sx={{flex: 1 }}>
                                {row.map_printed ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Producer Map Emailed
                              </Box>
                              <Box sx={{flex: 1 }}>
                                {row.producer_map_emailed ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell >
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Grower Map Emailed
                              </Box>
                              <Box sx={{flex: 1 }}>
                                {row.grower_map_emailed ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Cleaned
                              </Box>
                              <Box sx={{flex: 1}}>
                                {row.cleaned ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Evaluation Ready
                              </Box>
                              <Box sx={{flex: 1}}>
                                {row.evaluation_ready ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Producer Evaluation Emailed
                              </Box>
                              <Box sx={{flex: 1}}>
                                {row.producer_evaluation_emailed ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                              <Box sx={{flex: 1}}>
                                Grower Evaluation Emailed
                              </Box>
                              <Box sx={{flex: 1}}>
                                {row.grower_evaluation_emailed ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                     <TableRow>
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", maxWidth: "25rem" }}>
                          <Box sx={{flex: 1}}>
                            Trial Type
                          </Box>
                          <Box sx={{flex: 1}}>
                            {row.sales_team ? "Sales Trial" : "PD Trial"}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

