import {
  handleDelete,
  handleList,
  handleSave,
  handleSimpleList,
} from "./handler";
import {
  ListResponseData,
  PlotBed,
  TrialVariety,
  TrialVarietyInput,
} from "../types";
import { useMutation, useQuery } from "react-query";
import queryClient from "./queryClient";
import { isEmpty, unionBy, isNil } from "lodash";

const methods = {
  useList: (trialId?: number | string) => {
    return useQuery<TrialVariety[]>({
      queryKey: ["trial_varieties", trialId?.toString()],
      queryFn: () =>
        handleSimpleList({ url: `trials/${trialId}/trial_varieties` }),
      enabled: !!trialId,
    });
  },
  useListByVarietyIds: ({
    varietyIds,
    page,
  }: {
    varietyIds: string[];
    page?: number;
  }) => {
    return useQuery<ListResponseData<TrialVariety>>({
      queryKey: [
        "trial_varieties_by_variety_ids",
        varietyIds.join(","),
        page?.toString(),
      ],
      queryFn: () =>
        handleList({
          baseUrl: `trial_varieties/by_variety_ids`,
          params: [
            { key: "ids", value: varietyIds.join(",") },
            { key: "page", value: page },
          ],
        }),
      enabled: !isEmpty(varietyIds)  && !isNil(page),
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (trialVarietyInput: TrialVarietyInput) =>
        handleSave<TrialVariety>({
          baseUrl: `trials/${trialVarietyInput.trialId}/trial_varieties`,
          input: {
            ...trialVarietyInput,
            trialId: trialVarietyInput.trialId,
          },
        }),
      onSuccess: async (updatedTrialVariety: TrialVariety) => {
        // queryClient.removeQueries({
        //   queryKey: ["trial_varieties", updatedTrialVariety.trialId.toString()],
        //   exact: true
        // })
        queryClient.setQueryData(
          ["trial_varieties", updatedTrialVariety.trialId.toString()],
          (oldData: TrialVariety[] | undefined) => {
            if (oldData) {
              return unionBy([updatedTrialVariety], oldData, "id");
            } else {
              return [updatedTrialVariety];
            }
          }
        );
        queryClient.setQueryData(
          ["plot_beds", "trial", updatedTrialVariety.trialId.toString()],
          (oldData: PlotBed[] | undefined) => {
            return oldData
              ? [...oldData, ...updatedTrialVariety.plotBeds]
              : updatedTrialVariety.plotBeds;
          }
        );
      },
    });
  },
  useDelete: () => {
    return useMutation({
      mutationFn: (trialVariety: TrialVariety) =>
        handleDelete<{ id: string; trialId: string }>({
          baseUrl: `trials/${trialVariety.trialId}/trial_varieties`,
          id: trialVariety.id,
        }),
      onSuccess: async ({ id, trialId }: { id: string; trialId: string }) => {
        queryClient.setQueryData(
          ["trial_varieties", trialId],
          (oldData: TrialVariety[] | undefined) => {
            return (
              oldData?.filter(
                trialVariety => trialVariety.id?.toString() !== id?.toString()
              ) || []
            );
          }
        );
        queryClient.setQueryData(
          ["plot_beds", "trial", trialId.toString()],
          (oldData: PlotBed[] | undefined) => {
            return (
              oldData?.filter(
                plotBed => plotBed.plotsId?.toString() !== id?.toString()
              ) || []
            );
          }
        );
        queryClient.setQueryData(
          ["plot_beds", "variety", trialId],
          (oldData: PlotBed[] | undefined) => {
            return (
              oldData?.filter(
                plotBed => plotBed.plotsId?.toString() !== id?.toString()
              ) || []
            );
          }
        );
      },
    });
  },
};
export default methods;
