import "./index.scss";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import queryClient from "./api/queryClient";
import App from "./App";
import { SessionProvider } from "./contexts/Session.context";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import dayOfYear from "dayjs/plugin/dayOfYear";
import dayjs from "dayjs";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";

// /[a-zA-Z./:]+/g

Sentry.init({
  dsn:
    process.env.REACT_APP_ENV === "development"
      ? undefined
      : "https://28d05d262647eebff4985e0c6ec7ee2e@o4507306642440192.ingest.us.sentry.io/4508058512850944",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        window.location.origin,
        /https:\/\/staging.holadaygrow.com/,
        /https:\/\/staging.holadaygrow.com/,
        /https:\/\/holaday-api-upgrade-189d6c3457fe.herokuapp.com\/api\/(\w|\/)*/,
        /https:\/\/api.holadaygrow.com\/api\/(\w|\/)*/,
        /https:\/\/holaday-storage.s3.us-west-1.amazonaws.com\/(\w|\/)*/,
      ],
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  environment: process.env.REACT_APP_ENV,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    window.location.origin,
    /^https:\/\/staging.holadaygrow.com/,
    /^https:\/\/holaday-api-upgrade-189d6c3457fe\.herokuapp\.com\/api/,
    /^https:\/\/holadaygrow.com/,
    /https:\/\/api.holadaygrow.com\/api\/(\w|\/)*/,
    /https:\/\/holaday-storage.s3.us-west-1.amazonaws.com\/(\w|\/)*/,
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

dayjs.extend(dayOfYear);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SessionProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
            <Toaster position="bottom-right" reverseOrder={true} toastOptions={{ duration: 1000 }}/>
          </ThemeProvider>
        </SessionProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
