import {
  Box,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RegionMultiSelect from "../RegionMultiSelect";
import { Company, Region } from "../../types";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import CompanyMultiSelect from "../CompanyMultiSelect";
import {
  Configure,
  useSearchBox,
  UseSearchBoxProps,
} from "react-instantsearch";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const LOCAL_STORAGE_KEY = "localstorage-reports-searchquery-1"

export default function ReportsSearch(props: UseSearchBoxProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { query, refine } = useSearchBox(props);

  const [regions, setRegions] = React.useState<Region[]>([]);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null); // [2
  const [growers, setGrowers] = React.useState<Company[]>([]);
  const [shippers, setShippers] = React.useState<Company[]>([]);
  const [suppliers, setSuppliers] = React.useState<Company[]>([]);
  const [excludeYears, setExcludeYears] = React.useState<boolean>(true); // [3

  React.useEffect(() => {
    // Load query from local storage on component mount
    const storedQuery = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedQuery) {
      refine(storedQuery);
    }
  }, [refine]);

  React.useEffect(() => {
    // Save query to local storage on query change
    if (query) {
      localStorage.setItem(LOCAL_STORAGE_KEY, query);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, [query])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Configure
        analytics={false}
        filters={buildFilters({
          regions,
          growers,
          shippers,
          suppliers,
          excludeYears,
          endDate,
          startDate,
        })}
      />
      <Box sx={{ flex: 1 }}>
        <TextField
          id="input-with-icon-textfield"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          autoFocus={true}
          value={query}
          onChange={event => refine(event.target.value)}
          variant={"standard"}
        />
      </Box>
    {/*  <Box sx={{ display: "flex", flexDirection: (isSmallScreen ? "column" : "row"), gap: "1rem" }}>
        <Box sx={{ flexShrink: 1 }}>
          <FormControlLabel
            control={
              <Switch
                checked={excludeYears}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setExcludeYears(event.target.checked);
                }}
              />
            }
            label="Exclude Years"
          />
        </Box>
        { isSmallScreen && (
          <Box>
            <DatePicker
              label={"Start Date"}
              sx={{ width: "100%" }}
              value={startDate}
              onChange={newValue => setStartDate(newValue)}
            />
          </Box>
        )}
        { isSmallScreen && (
          <Box>
            <DatePicker
              label={"End Date"}
              sx={{ width: "100%" }}
              value={endDate}
              onChange={newValue => setEndDate(newValue)}
            />
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box>
            <CompanyMultiSelect
              setCompanies={setGrowers}
              label={"Growers"}
              companies={growers}
              companyCategoryName={"grower"}
            />
          </Box>
          <Box>
            <CompanyMultiSelect
              setCompanies={setSuppliers}
              label={"Suppliers"}
              companies={suppliers}
              companyCategoryName={"supplier"}
            />
          </Box>
          { !isSmallScreen && (
            <Box>
              <DatePicker
                label={"Start Date"}
                sx={{ width: "100%" }}
                value={startDate}
                onChange={newValue => setStartDate(newValue)}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box>
            <CompanyMultiSelect
              setCompanies={setShippers}
              label={"Shippers"}
              companies={shippers}
              companyCategoryName={"shipper"}
            />
          </Box>
          <Box>
            <RegionMultiSelect
              regions={regions}
              setRegions={setRegions}
              label={"Regions"}
            />
          </Box>
          { !isSmallScreen && (
            <Box>
              <DatePicker
                label={"End Date"}
                sx={{ width: "100%" }}
                value={endDate}
                onChange={newValue => setEndDate(newValue)}
              />
            </Box>
          )}
        </Box>
      </Box>*/}
    </Box>
  );
}

function buildFilters({
  regions,
  growers,
  shippers,
  suppliers,
  excludeYears,
  startDate,
  endDate,
}: {
  excludeYears: boolean;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  suppliers: Company[];
  shippers: Company[];
  growers: Company[];
  regions: Region[];
}) {
  let filters = [];
  if (excludeYears) {
    if (startDate) {
      filters.push(`end_wet_date_days >= ${startDate.dayOfYear()}`);
    }
    if (endDate) {
      filters.push(`start_wet_date_days <= ${endDate.dayOfYear()}`);
    }
  } else {
    if (startDate) {
      filters.push(`end_wet_date_unix >= ${startDate.unix()}`);
    }
    if (endDate) {
      filters.push(`start_wet_date_unix <= ${endDate.unix()}`);
    }
  }
  const facetFilters = buildFacetFilters({
    suppliers,
    shippers,
    growers,
    regions,
  }).join(" AND ");
  if (facetFilters.length > 0) {
    filters.push(facetFilters);
  }
  return filters.join(" AND ");
}

function buildFacetFilters({
  suppliers,
  shippers,
  growers,
  regions,
}: {
  suppliers: Company[];
  shippers: Company[];
  growers: Company[];
  regions: Region[];
}) {
  let facetFilters = [];
  if (suppliers.length !== 0) {
    const arr = suppliers.map(
      (supplier: Company) => `supplier_id:${supplier.id}`
    );
    facetFilters.push(arr.join(" OR "));
  }
  if (shippers.length !== 0) {
    const arr = shippers.map((shipper: Company) => `shippers_id:${shipper.id}`);
    facetFilters.push(arr.join(" OR "));
  }
  if (growers.length !== 0) {
    const arr = growers.map((grower: Company) => `growers_id:${grower.id}`);
    facetFilters.push(arr.join(" OR "));
  }
  if (regions.length !== 0) {
    const arr = regions.map((region: Region) => `regions_id:${region.id}`);
    facetFilters.push(arr.join(" OR "));
  }
  return facetFilters;
}
