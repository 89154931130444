import React from 'react'

export default function usePersistState<T>(key: string, initialValue: T): [T, (t: T) => void] {
  const _initial_value = React.useMemo(() => {
    const local_storage_value_str = localStorage.getItem('state:' + key);
    // If there is a value stored in localStorage, use that
    if(local_storage_value_str) {
      return JSON.parse(local_storage_value_str);
    }
    // Otherwise use initial_value that was passed to the function
    return initialValue;
  }, []);

  const [state, setState] = React.useState(_initial_value);

  React.useEffect(() => {
    const state_str = JSON.stringify(state); // Stringified state
    localStorage.setItem('state:' + key, state_str) // Set stringified state as item in localStorage
  }, [state]);

  return [state, setState];
}