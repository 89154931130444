import { useInfiniteHits } from "react-instantsearch";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Hit } from "algoliasearch";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  IconButton,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { VarietyAlgolia } from '../../types'

export default function ReportsVarietyList(props: any) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { items, isLastPage, showMore } = useInfiniteHits(props);

  const sentinelRef = React.useRef(null);

  React.useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            // Load more hits
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  const { checked, toggleChecked } = props;
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            { isSmallScreen && (
              <TableCell></TableCell>
            )}
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Name
              </Typography>
            </TableCell>
            { !isSmallScreen && (
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  Supplier
                </Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Commodity
              </Typography>
            </TableCell>
            { !isSmallScreen && (
              <>
                <TableCell>
                  <Typography variant="body2" gutterBottom>
                    Growers
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" gutterBottom>
                    Shippers
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" gutterBottom>
                    Regions
                  </Typography>
                </TableCell>
              </>
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row: any) => (
            <AlgoliaVarietyRow
              checked={checked}
              toggleChecked={toggleChecked}
              row={row}
              key={row.objectID}
            />
          ))}
        </TableBody>
      </Table>
      <div ref={sentinelRef} aria-hidden="true" />
    </Box>
  );
}

function AlgoliaVarietyRow({ checked, toggleChecked, row }: { checked: string[], toggleChecked: (arg: string) => void, row: Hit<VarietyAlgolia> }){
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState<boolean>(false)

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setOpen(o => !o)
  }
  return (
    <>
      <TableRow key={row.objectID} sx={{ '& > *': { borderBottom: 'unset' } }}>
        { isSmallScreen && (
          <TableCell>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={handleToggle}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell>
          <Link to={`/reports/single/${row.objectID}`}>
            <Typography variant="body2">{row.name}</Typography>
          </Link>
        </TableCell>

        { !isSmallScreen && (
          <TableCell>
            <Typography variant="body2">{row.company.name}</Typography>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2">
            {row.commodities.join(", ")}
          </Typography>
        </TableCell>
        { !isSmallScreen && (
          <>
            <TableCell>
              <Typography variant="body2">
                {row.growers.join(", ")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {row.shippers.join(", ")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {row.regions.join(", ")}
              </Typography>
            </TableCell>
          </>
        )}
        <TableCell>
          <Checkbox
            checked={checked.indexOf(row.objectID) !== -1}
            onClick={() => toggleChecked(row.objectID)}
          />
        </TableCell>
      </TableRow>
      {isSmallScreen && (
        <TableRow sx={{ '& > *': { borderBottom: '0' } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ mb: "2rem", ml: 1, mr: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                 Details of {row.name}
                </Typography>
                 <Table size="small" aria-label="details">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" gutterBottom>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" gutterBottom>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography>
                          Supplier
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2" gutterBottom>
                          {row.company.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography>
                          Growers
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2" gutterBottom>
                          {row.growers.join(", ")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography>
                          Shippers
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2" gutterBottom>
                          {row.shippers.join(", ")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography>
                          Regions
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2" gutterBottom>
                          {row.regions.join(", ")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
